import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import FinancialCharts from "../../../components/financial-charts/financial-charts";
import Icon from "../../../components/custom-widgets/icon";
import Button from "../../../components/custom-widgets/button";
import MktoForm from "../../../components/mkto-form/mkto-form";
import InvestorRelationsNavData from "../../../data/investor-relations/investor-relations-nav-data";
import Navigation from "../../../components/navigation/navigation";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import InfoCard from "../../../components/card/info-card";
import TransferImage from "../../../images/employees/transfer-agent-icon.png";

import showMktoForm from "../../../helpers/showMktoForm";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const InvestorRelations = () => {
  const title = "Investor Relations";
  const description =
    "Interested in investing in WaFd Bank? View shareholder info and get the latest investment news here.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/hero-investor-relations-12212022-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/government-building-01-550.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card02: file(relativePath: { eq: "cards/skyscraper-buildings-03-550.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card03: file(relativePath: { eq: "cards/pen-writing-on-page-01-550.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card04: file(relativePath: { eq: "cards/financing-paperwork-02-550.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-investor-relations-12212022.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "investor-relations-hero",
    ...getHeroImgVariables(imgData),
    altText: "Corporate business persons discussing new project and sharing ideas in the workplace.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "At WaFd Bank, We Trace Our Roots Back to 1917"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Investor Relations"
    }
  ];

  const LatestFinancialNews = ({ date, id, url, text }) => {
    return (
      <div className="mb-4">
        <h4>{date}</h4>
        {url ? (
          <a id={id} className="text-decoration-none d-flex" target="_blank" href={url} rel="noreferrer">
            {url.includes(".pdf") && <Icon name="file-pdf" class="mr-2 mt-1" />}
            {text}
          </a>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </div>
    );
  };

  const FinancialNewsSection = () => {
    const LatestFinancialNewsData = [
      {
        date: "December 6, 2024",
        id: "latest-financial-news-pdf", // please do NOT change this.
        text: "Investor Presentation As of 9/30/2024",
        url: "/documents/financial-news/2024/wafd-bank-investor-presentation-20241209.pdf"
      }
    ];

    return (
      <section id="latest-financial-news-section" className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-6 d-none d-md-block">
              <StaticImage
                className="mb-4"
                src="../../../images/financial-news-730.jpg"
                alt="Man looking at phone while using laptop"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-12 col-md-6">
              <h2 className="text-success font-weight-semi-bold">The Latest Financial News</h2>
              {LatestFinancialNewsData.map((articleData) => (
                <LatestFinancialNews {...articleData} key={articleData.id} />
              ))}
              <Button
                id="see-all-financial-news-button"
                class="btn-link"
                showIcon={false}
                text="See All Financial News"
                type="link"
                url="/about-us/investor-relations/financial-news"
              />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const FinancialInformation = () => {
    return (
      <section className="container">
        <h2>Financial Information</h2>
        <h4>
          WaFd Bank is traded on Nasdaq using the symbol WAFD. We've been publicly traded since November 1982, and have
          grown through both de-novo expansion and acquisitions.
        </h4>
        <Link
          to="/about-us/investor-relations/company-history"
          id="company-history-link"
          className="text-decoration-none"
        >
          WaFd Bank Company History
          <Icon name="arrow-right" class="ml-2" />
        </Link>
      </section>
    );
  };

  const FinancialContacts = () => {
    const bradGoodeInfo = {
      className: "col-12 mb-3",
      title: "Brad Goode",
      text: "Chief Marketing & Communications Officer<br/>Investor Relations",
      links: [
        {
          id: "ir-phone-number",
          type: "phone",
          to: "206-626-8178"
        },
        {
          id: "ir-email",
          type: "email",
          to: "brad.goode@wafd.com"
        }
      ]
    };
    const transferAgentInfo = {
      className: "col-12",
      title: "Transfer Agent",
      text: "Broadridge Corporate Issuer Solutions<br/>P.O. Box 1342<br/>Brentwood, NY 11717",
      links: [
        {
          id: "ta-phone-number",
          type: "phone",
          to: "844-983-0877"
        },
        {
          id: "ta-email",
          type: "email",
          to: "shareholder@broadridge.com"
        }
      ]
    };

    return (
      <section className="container">
        {/* Mobile View */}
        <div className="row d-flex d-md-none">
          <InfoCard {...bradGoodeInfo}>
            {{
              image: (
                <StaticImage
                  src="../../../images/employees/ir-brad-goode.jpg"
                  alt="Brad Goode"
                  placeholder="blurred"
                  quality="100"
                  width={64}
                  height={64}
                />
              )
            }}
          </InfoCard>
          <InfoCard {...transferAgentInfo}>
            {{
              image: <img src={TransferImage} alt="Transfer Agent" width={64} height={64} />
            }}
          </InfoCard>
        </div>
        {/* Desktop View */}
        <div className="row d-none d-md-flex">
          <div className="col-sm-2 mb-4 mb-sm-0">
            <StaticImage
              src="../../../images/employees/ir-brad-goode.jpg"
              alt="Brad Goode"
              placeholder="blurred"
              quality="100"
              width={118}
            />
          </div>
          <div className="col-sm-4 mb-sm-0">
            <strong id="ir-name" className="mb-3">
              Brad Goode
            </strong>
            <div id="ir-position">Chief Marketing &amp; Communications Officer</div>
            <div id="ir-title">Investor Relations</div>
            <a className="d-block text-decoration-none" href="tel:206-626-8178" id="ir-phone-number">
              <Icon class="mr-2" name="phone" />
              206-626-8178
            </a>
            <a className="d-block text-decoration-none" href="mailto:brad.goode@wafd.com" id="ir-email">
              <Icon class="mr-2" name="envelope" />
              brad.goode@wafd.com
            </a>
          </div>
          <div className="col-sm-4 mb-sm-0">
            <strong id="ta-title" className="mb-3">
              Transfer Agent
            </strong>
            <div id="ta-name">Broadridge Corporate Issuer Solutions</div>
            <div id="ta-address-1">P.O. Box 1342</div>
            <div id="ta-address-2">Brentwood, NY 11717</div>
            <a className="d-block text-decoration-none" href="tel:844-983-0877" id="ta-phone-number">
              <Icon class="mr-2" name="phone" />
              844-983-0877
            </a>
            <a className="d-block text-decoration-none" href="mailto:shareholder@broadridge.com" id="ta-email">
              <Icon class="mr-2" name="envelope" />
              shareholder@broadridge.com
            </a>
          </div>
        </div>
      </section>
    );
  };

  const MoreInformation = () => {
    return (
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>More Information</h2>
              <div className="row mb-4">
                <div className="col-lg-6">
                  Join WaFd Bank's financial news distribution email list or find additional information and resources.
                </div>
              </div>
              {/* Desktop Btns */}
              <div className="row mb-4 d-none d-md-flex">
                <div className="col-12 d-flex align-items-start flex-wrap">
                  <button
                    id="investor-distribution-email"
                    className="btn btn-link mr-2 mb-2"
                    onClick={() => showMktoForm(1428)}
                  >
                    Join Financial News Email List
                    <Icon name="envelope" lib="far" class="ml-2 mt-1" />
                  </button>
                  <a
                    id="investor-kit-email"
                    className="text-decoration-none btn btn-link"
                    href="mailto:info@wafd.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Request an Investor Kit
                    <Icon name="envelope" lib="far" class="ml-2 mt-1" />
                  </a>
                </div>
              </div>
              <ul className="list-unstyled mb-4 mb-md-0">
                <li>
                  <a
                    id="annual-report-pdf"
                    className="text-decoration-none"
                    href="/documents/wfsl-financial-data/wafd-bank-2023-annual-report.pdf"
                    title="WaFd Bank 2023 Annual Report"
                    target="_blank"
                  >
                    <Icon name="file-pdf" class="mr-2 mt-1" />
                    2023 Annual Report
                  </a>
                </li>
                <li>
                  <a
                    id="proxy-statement-pdf"
                    className="text-decoration-none"
                    rel="noopener noreferrer"
                    href="https://www.sec.gov/ix?doc=/Archives/edgar/data/936528/000093652823000219/wfsl-20231220.htm"
                    title="WaFd Bank 2023 Proxy Statement"
                    target="_blank"
                  >
                    <Icon name="file-pdf" class="mr-2 mt-1" />
                    2023 Proxy Statement
                  </a>
                </li>
                <li className="mb-0">
                  <a
                    id="capital-ratios-pdf"
                    className="text-decoration-none"
                    href="/documents/wafd-bank-capital-ratios.pdf"
                    title="WaFd Bank Current Capital Ratios"
                    target="_blank"
                  >
                    <Icon name="file-pdf" class="mr-2 mt-1" />
                    Current Capital Ratios
                  </a>
                </li>
              </ul>
              {/* Mobile Btns */}
              <div className="row d-flex d-md-none">
                <div className="col-12 d-flex align-items-start flex-wrap">
                  <button
                    id="investor-distribution-email"
                    className="btn btn-link mb-2 w-100"
                    onClick={() => showMktoForm(1428)}
                  >
                    Join Financial News Email List
                    <Icon name="envelope" lib="far" class="ml-2 mt-1" />
                  </button>
                  <a
                    id="investor-kit-email"
                    className="text-decoration-none btn btn-link w-100"
                    href="mailto:info@wafd.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Request an Investor Kit
                    <Icon name="envelope" lib="far" class="ml-2 mt-1" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <MktoForm />
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...InvestorRelationsNavData} />
      <section id="investor-relations-title" className={`container pb-0`}>
        <h1>Investor Relations</h1>
      </section>

      <FinancialNewsSection />
      <FinancialCharts />
      <div className="container">
        <div className="w-100 border-top-1"></div>
      </div>
      <FinancialInformation />
      <FinancialContacts />
      <MoreInformation />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default InvestorRelations;
