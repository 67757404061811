import React, { useEffect, useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import { useStaticQuery, graphql } from "gatsby";
import { cloneDeep } from "lodash";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Icon from "../custom-widgets/icon";

//Styles
import styles from "./financial-charts.module.scss";

const FinancialChart = ({
  id,
  title,
  subtitle,
  dataKey,
  description,
  chartsData,
  chartType,
  quarterYearClasses,
  isLast
}) => {
  const barClasses = ["Blue40", "Blue60", "Blue80", "Green40", "Green60", "Green80"];
  const MAX_BAR_SIZE = 62;
  const [showData, setShowData] = useState(false);

  const toggleShowData = () => {
    setShowData(!showData);
  };

  const formatValue = (value, dataKey) => {
    switch (dataKey) {
      case "assets":
      case "capital":
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(value);
      case "eps":
      case "cashDivPerShare":
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        }).format(value);
      case "returnOnCapital":
      case "interestSpread":
        return `${value}%`;
      default:
        return value;
    }
  };

  const CustomBarLabel = (props) => {
    const { x, y, width, value, dataKey } = props;
    if (width < MAX_BAR_SIZE) return null; // No label when the bar width is less than expected.

    return (
      <text x={x + width / 2} y={y} fill="#202324" textAnchor="middle" dy={-6} aria-hidden="true">
        {formatValue(value, dataKey)}
      </text>
    );
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, fill, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text textAnchor="middle" x={0} y={0} dy={16} fill={fill} aria-hidden="true">
          {payload.value}
        </text>
      </g>
    );
  };

  const getBarClass = (entry, index) => {
    if (chartType === "year") return barClasses[index % barClasses.length];
    return quarterYearClasses[entry.year];
  };

  const getAriaLabel = (entry, labelDataKey, dataKey) => {
    const valueDisclaimer = dataKey === "assets" ? "millions" : "";
    return `${formatValue(entry[dataKey], dataKey)} ${valueDisclaimer} during year ${entry[labelDataKey]}`;
  };

  const CustomBarRender = (props) => {
    const { fill, x, y, width, height, className, ariaLabel, tabIndex } = props;
    return (
      <rect
        aria-roledescription="bar"
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        className={className}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      />
    );
  };

  const ChartLegend = ({ isMobile = false }) => {
    return chartsData.map((chart, index) => (
      <div className="col mb-3 mt-1" key={`chart-${index}`}>
        <div className={`d-inline ${isMobile ? "" : styles[`bb${barClasses[index % 6]}`]}`} tabIndex={0}>
          <span className="font-weight-bold">{chart.time}</span> - {formatValue(chart[dataKey], dataKey)}
        </div>
      </div>
    ));
  };

  const ChartSummary = ({ title, subtitle, description }) => {
    return (
      <div className="mt-1 mb-3">
        <h5 className="mb-0">{title}</h5>
        <div className="mb-2" dangerouslySetInnerHTML={{ __html: subtitle }} />
        <p className="mb-0" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  };

  const ShowDataDropdown = () => {
    return (
      <div className="d-none d-sm-flex mb-3">
        <button className={styles.dropdownToggle} onClick={toggleShowData}>
          {showData ? "Hide data" : "Show data"}
          <Icon lib="fas" name={showData ? "chevron-up" : "chevron-down"} class="mt-1 ml-2 float-right" />
        </button>
      </div>
    );
  };

  return (
    <>
      <ResponsiveContainer className={`d-none d-sm-block ${styles.chartContainer}`} width="100%" height={305}>
        <BarChart
          data={chartsData}
          margin={{ top: 15, bottom: 20 }}
          role="graphics-document"
          tabIndex={0}
          aria-labelledby={`${id}-title`}
        >
          <title id={`${id}-title`}>{title + " Chart"}</title>
          <CartesianGrid stroke="#EDEEEE" />
          <XAxis dataKey="time" dy={5} tick={<CustomizedAxisTick />} />
          <YAxis type="number" domain={[0, Math.max(...chartsData.map((data) => data[dataKey])) * 1.2]} hide={true} />
          <Bar
            dataKey={dataKey}
            fill="#008733"
            barSize={MAX_BAR_SIZE}
            label={<CustomBarLabel dataKey={dataKey} />}
            shape={<CustomBarRender />}
          >
            {chartsData.map((entry, index) => {
              const barClass = getBarClass(entry, index);
              const ariaLabel = getAriaLabel(entry, "time", dataKey);
              return (
                <Cell className={styles[`fill${barClass}`]} key={`cell-${index}`} tabIndex={0} ariaLabel={ariaLabel} />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <ShowDataDropdown />
      {/* Desktop Chart Legend - Hidden by Show data dropdown */}
      <div className={`d-none row row-cols-2 mb-4 ${showData ? "d-sm-flex" : ""}`}>
        <ChartLegend />
      </div>
      <ChartSummary title={title} subtitle={subtitle} description={description} />
      <div className={`d-flex d-sm-none mt-1 ${styles.chartSeparator} ${isLast ? "border-bottom-0 mb-0" : ""}`} />
      {/* Mobile Chart Legend */}
      <div className={`d-flex d-sm-none row row-cols-2 mb-4`}>
        <ChartLegend isMobile={true} />
      </div>
    </>
  );
};

const FinancialCharts = () => {
  let [chartType, setChartType] = useState("year");
  let [yearRange, setYearRange] = useState({ startYear: "2017", endYear: "2024" });
  let [quarterCount, setQuarterCount] = useState(5);

  useEffect(() => {
    handleFilterChart();
  }, [chartType]);

  const fileData = useStaticQuery(graphql`
    query {
      financialData: allWafdFinancialChartsDataCsv {
        nodes {
          time: QuarterEnding
          assets: Assets
          capital: Capital
          eps: Eps
          cashDivPerShare: CashDivPerShare
          returnOnCapital: ReturnOnCapital
          interestSpread: InterestSpread
          returnOnAvgEquityYearEnd: ReturnOnAvgEquityYearEnd
        }
      }
    }
  `);

  let financialData = cloneDeep(fileData.financialData.nodes);
  let yearEndData = cloneDeep(fileData.financialData.nodes);
  let last8QuarterYears = [];
  let last5QuarterYears = [];
  const getQuarterEndData = (noOfQuarters) => {
    let requestedData = cloneDeep(financialData.slice(-noOfQuarters));
    return requestedData.map((data) => {
      const yearPosition = data.time.lastIndexOf("/");
      const year = data.time.slice(yearPosition + 1);
      data["year"] = year;
      if (noOfQuarters == 8) last8QuarterYears.push(year);
      if (noOfQuarters == 5) last5QuarterYears.push(year);
      data.time = data.time.slice(0, yearPosition);
      return data;
    });
  };

  const last8QuartersData = getQuarterEndData(8);
  const last5QuartersData = getQuarterEndData(5);
  last8QuarterYears = [...new Set(last8QuarterYears)];
  last5QuarterYears = [...new Set(last5QuarterYears)];

  // Map up to 3 quarter years to 3 specific colors, ex: { 2020: color1, 2021: color2 }.
  // Used as object for easy access of color when the year is known.
  const quarterYearClasses = last8QuarterYears.reduce((acc, val, currentIndex) => {
    const quarterBarClasses = ["Green40", "Green60", "Green80"];
    acc[val] = quarterBarClasses[currentIndex];
    return acc;
  }, {});

  const handleFilterChart = () => {
    if (chartType === "year") {
      const selectedYearEndChartData = yearEndChartData.filter(
        (yearData) => yearData.time >= yearRange.startYear && yearData.time <= yearRange.endYear
      );
      setChartsData(selectedYearEndChartData);
    } else if (chartType === "quarter") {
      if (quarterCount === 8) {
        setChartsData(last8QuartersData);
      } else if (quarterCount === 5) {
        setChartsData(last5QuartersData);
      }
    }
  };

  let chartYears = [];
  let yearEndChartData = [];
  for (const [i, x] of yearEndData.entries()) {
    if (x.time.startsWith("12/31")) {
      if (yearEndData.length >= i + 4) {
        let yearData = yearEndData.slice(i, i + 4);
        yearData[yearData.length - 1].eps = yearData
          .map((year) => +year.eps)
          .reduce((prev, curr) => prev + curr)
          .toFixed(2);
        yearData[yearData.length - 1].cashDivPerShare = yearData
          .map((year) => +year.cashDivPerShare)
          .reduce((prev, curr) => prev + curr)
          .toFixed(2);
        yearData[yearData.length - 1].returnOnCapital = yearData[yearData.length - 1].returnOnAvgEquityYearEnd;
        yearEndChartData.push(yearData[yearData.length - 1]);
      }
    }
  }
  yearEndChartData.map((year) => {
    year.time = new Date(year.time).getFullYear();
    chartYears.push(year.time);
  });
  const defaultYearEndChartData = yearEndChartData.filter(
    (yearData) => yearData.time >= yearRange.startYear && yearData.time <= yearRange.endYear
  );

  //To do: per hooks rules move this too top level.
  let [chartsData, setChartsData] = useState(defaultYearEndChartData);
  const rangeYears = chartYears.reverse();

  const financialChartsData = [
    {
      id: 1,
      title: "Total Assets",
      subtitle: "Dollars in Millions",
      dataKey: "assets",
      description:
        "The Total Assets chart shows the trend in the amount of Assets held by WaFd Bank, in millions, at the end of each period displayed."
    },
    {
      id: 2,
      title: "Stockholders Equity",
      subtitle: "Dollars in Millions",
      dataKey: "capital",
      description:
        "The Stockholders Equity chart shows the trend in WaFd Bank's total net worth or value as of the end of each period displayed. Stockholders Equity is the accumulated amount that stockholders' have invested combined with the accumulated income earned and reinvested since inception."
    },
    {
      id: 3,
      title: "Net Income Per Diluted Share",
      subtitle: "&dollar;",
      dataKey: "eps",
      description:
        "The Net Income Per Diluted Share chart shows the trend in the amount of earnings for each share of stock held by common shareholders for the period displayed. The number of shares considered includes outstanding stock options not yet exercised to capture their potential to dilute the earnings of the existing shareholders."
    },
    {
      id: 4,
      title: "Cash Dividends Per Share",
      subtitle: "&dollar;",
      dataKey: "cashDivPerShare",
      description:
        "The Cash Dividends Per Share chart shows the trend in the cash dividend WaFd Bank has distributed to each common shareholder for the periods displayed."
    },
    {
      id: 5,
      title: "Return on Average Equity",
      subtitle: "Annualized &percnt;",
      dataKey: "returnOnCapital",
      description:
        "The Return on Average Equity chart shows the trend in WaFd Bank's income as a percentage of their average Shareholders Equity. Return on Equity is a gauge of how efficiently a company is generating profits through use of their capital."
    },
    {
      id: 6,
      title: "Interest Rate Spread",
      subtitle: "End of Year &percnt;",
      dataKey: "interestSpread",
      description:
        "The Interest Rate Spread chart displays the trend in the difference between the earning interest rate on WaFd Bank's assets and the interest rate the bank is paying on our liabilities as of the end of each period selected."
    }
  ];

  const TimeTabItem = ({ timeString, onSelect }) => {
    return (
      <Nav.Item className="col-6 col-sm-4 col-md-3 col-lg-2 text-center">
        <Nav.Link
          className="text-capitalize"
          onSelect={() => onSelect(timeString)}
          eventKey={`${timeString}-end-tab-content`}
        >
          {timeString} End
        </Nav.Link>
      </Nav.Item>
    );
  };

  const TimeTabs = ({ className, onSelect }) => {
    return (
      <Nav variant="pills" className={className}>
        <TimeTabItem timeString="year" onSelect={onSelect} />
        <TimeTabItem timeString="quarter" onSelect={onSelect} />
      </Nav>
    );
  };

  const QuarterYearBullets = ({ yearsArray = [], quarterYearClasses = {} }) => {
    return yearsArray.map((year) => {
      const backgroundClass = `bg${quarterYearClasses[year]}`;
      return (
        <>
          {/* Desktop view */}
          <span className="d-none d-sm-inline" key={year}>
            <span className={`${styles.squareBox} ${styles[backgroundClass]} align-middle d-inline-block mx-2`}>
              &nbsp;
            </span>
            <span className="align-middle">
              <strong>{year}</strong>
            </span>
          </span>
          {/* Mobile view */}
          <span className="d-inline d-sm-none mr-2">
            <strong>{year}</strong>
          </span>
        </>
      );
    });
  };

  return (
    <section className="container">
      <h2>Performance Data</h2>
      <Tab.Container defaultActiveKey="year-end-tab-content">
        {/* Desktop Tabs */}
        <TimeTabs onSelect={setChartType} className={`d-none d-md-flex row no-gutters mb-3 ${styles.financialTab}`} />
        {/* Mobile Tabs */}
        <TimeTabs
          onSelect={setChartType}
          className={`d-flex d-md-none row no-gutters mb-3 ${styles.financialTabMobile}`}
        />

        <Tab.Content className="pb-1">
          <Tab.Pane eventKey="year-end-tab-content" id="year-end-tab-content">
            <div className="d-md-flex align-items-end">
              <p className="mb-1 mr-3">Range:</p>
              <form className="form-inline align-items-end mr-4">
                <div className="form-group flex-column mb-0">
                  <label htmlFor="startYear">Start Year</label>
                  <select
                    id="startYear"
                    defaultValue={yearRange.startYear}
                    onChange={(e) => setYearRange({ startYear: e.target.value, endYear: yearRange.endYear })}
                    className="form-control w-auto custom-select-chevron border-radius-6 d-block"
                  >
                    {rangeYears.map((year) => (
                      <option key={year} value={year} disabled={year > yearRange.endYear}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="mb-1 mx-3">to</p>
                <div className="form-group flex-column mb-0">
                  <label htmlFor="endYear">End Year</label>
                  <select
                    id="endYear"
                    defaultValue={yearRange.endYear}
                    onChange={(e) => setYearRange({ startYear: yearRange.startYear, endYear: e.target.value })}
                    className="form-control w-auto custom-select-chevron border-radius-6 d-block"
                  >
                    {rangeYears.map((year) => (
                      <option key={year} value={year} disabled={year < yearRange.startYear}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <button className="btn btn-primary mt-4" onClick={handleFilterChart}>
              Filter Charts
            </button>
          </Tab.Pane>
          <Tab.Pane eventKey="quarter-end-tab-content" id="quarter-end-tab-content">
            <Tab.Container defaultActiveKey="last-5-quarter-tab-content">
              <Nav variant="pills" className={`d-inline-flex ${styles.quarterTab}`}>
                <Nav.Item>
                  <Nav.Link onSelect={() => setQuarterCount(5)} eventKey="last-5-quarter-tab-content">
                    Last 5 Quarters
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onSelect={() => setQuarterCount(8)} eventKey="last-8-quarter-tab-content">
                    Last 8 Quarters
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="d-inline-flex ml-2">
                <Tab.Pane eventKey="last-5-quarter-tab-content" id="last-5-quarter-tab-content">
                  <QuarterYearBullets yearsArray={last5QuarterYears} quarterYearClasses={quarterYearClasses} />
                </Tab.Pane>
                <Tab.Pane eventKey="last-8-quarter-tab-content" id="last-8-quarter-tab-content">
                  <QuarterYearBullets yearsArray={last8QuarterYears} quarterYearClasses={quarterYearClasses} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <button className="btn btn-primary mt-4 d-block" onClick={handleFilterChart}>
              Filter Charts
            </button>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <div className="container pt-4">
        <div className="row">
          {financialChartsData.map((chartData, index) => (
            <div className="col-md-6 mb-md-4" key={chartData.id}>
              <FinancialChart
                {...chartData}
                chartsData={chartsData}
                chartType={chartType}
                quarterYearClasses={quarterYearClasses}
                isLast={index === financialChartsData.length - 1}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FinancialCharts;
