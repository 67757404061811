import React, { useCallback } from "react";
import { Link } from "gatsby";

import Icon from "../custom-widgets/icon";

const LinkUrl = ({ to = "", type = "external", text = null, className = "", id = "" }) => {
  const hrefPrefix = (type) => {
    switch (type) {
      case "email":
        return "mailto:";
      case "phone":
        return "tel:";
      default:
        return "";
    }
  };

  const renderIcon = useCallback(() => {
    switch (type) {
      case "email":
        return <Icon name="envelope-open-text" lib="far" class="mr-2" />;
      case "phone":
        return <Icon name="phone-alt" class="mr-2" />;
      default:
        return <Icon name="external-link" lib="far" class="mr-2" />;
    }
  }, [type]);

  const hrefString = hrefPrefix(type) + to;
  return (
    <a href={hrefString} id={id} className={`text-decoration-none d-block ${className}`}>
      {renderIcon()}
      {text || to}
    </a>
  );
};

const InfoCard = ({ className = "", title = "", text = null, links = [], children, btn = null }) => {
  const { image } = children;
  return (
    <div className={`card border-0 ${className}`}>
      <div className="bg-light card-body border-radius-top-12">
        <div className="row mb-3">
          <div className="col">
            <h5 className="mb-0">{title}</h5>
            {text && <p className="mb-0" dangerouslySetInnerHTML={{ __html: text }} />}
          </div>
          <div className="col-auto align-content-center">{image}</div>
        </div>
        {links.map((link, index) => (
          <LinkUrl className={links.length === index + 1 ? "" : "mb-2"} {...link} key={index} />
        ))}
      </div>
      {btn && (
        <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
          <Link className="btn btn-primary btn-block" to={btn.url}>
            {btn.text}
          </Link>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
